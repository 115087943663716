import React from "react"
import { API } from "aws-amplify"
import { FormContainer, FormField } from "./styles"
import { useForm } from "react-hook-form"
import { sesCall } from "../../../graphql/queries"
import { isBrowser } from "../../../context/utils"

const RegistrationForm = (props) => {
  const { setisMessageSent, title } = props
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true })

  const selectedCourse = isBrowser()
    ? window.localStorage.getItem("selected-course")
    : null

  const onSubmit = (data) => {
    sesNotification(data)
    setisMessageSent(true)
  }

  async function sesNotification(data) {
    const {
      name,
      email,
      phone,
      organisation,
      designation,
      message,
    } = data
    try {
      await API.graphql({
        query: sesCall,
        variables: {
          msg: {
            name: name,
            email: email,
            phone: phone,
            course: selectedCourse,
            organisation: organisation,
            designation: designation,
            message: message,
          },
        },
      })
    } catch (err) {
      console.log("error triggering lambda function: ", err)
    }
  }
  return (
    <FormContainer className="contactForm">
      <p className="title">{selectedCourse}</p>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <label>Full Name</label>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              {...register("name", { required: true })}
            />
          </FormField>
          <FormField>
            <label>Phone</label>
            <input
              type="text"
              name="phone"
              placeholder="98888888"
              {...register("phone", { required: true })}
            />
          </FormField>
          <FormField>
            <label>Email Address</label>
            <input
              type="email"
              name="name"
              placeholder="Email Address"
              {...register("email", { required: true })}
            />
          </FormField>
          <FormField>
            <label>Organisation</label>
            <input
              type="text"
              name="organisation"
              placeholder=""
              {...register("organisation")}
            />
          </FormField>
          <FormField>
            <label>Designation</label>
            <input
              type="text"
              name="designation"
              placeholder=""
              {...register("designation")}
            />
          </FormField>
          <FormField>
            <label>Your message</label>
            <textarea
              placeholder="Message"
              {...register("message")}
            ></textarea>
          </FormField>
          <FormField>
            <input
              type="submit"
              name="sbtn"
              value="Enquire Now"
            />
          </FormField>
        </form>
      </div>
    </FormContainer>
  )
}

export default RegistrationForm
