import React, { useState } from "react"
import { API } from "aws-amplify"
import {
  FormContainer,
  SuccessMessageContainer,
  AnimationContainer,
} from "../../../components/product-detail/CourseRegistration/styles"
import "../../../components/product-detail/CourseRegistration/form.scss"
import Lottie from "lottie-react"
import { useForm } from "react-hook-form"
import { sesCall } from "../../../graphql/queries"
import RegistrationForm from "./RegistrationForm"
import successAnimation from "../../../data/message-sent-success.json"

const CourseRegistrationForm = (props) => {
  const [isMessageSent, setisMessageSent] = useState(false)

  const onSubmit = (data) => {
    sesNotification(data)
    window.localStorage.setItem("Email", data.email)
    // handleClick(data.email)
  }

  async function sesNotification(data) {
    const { name, email, phone, courseTitle, course_date } =
      data
    try {
      await API.graphql({
        query: sesCall,
        variables: {
          msg: {
            name: name,
            email: email,
            phone: phone,
            course: courseTitle,
            course_date: course_date,
          },
        },
      })
    } catch (err) {
      console.log("error triggering lambda function: ", err)
    }
  }

  return (
    <FormContainer className="contactForm">
      <div>
        {isMessageSent ? (
          <SuccessMessageContainer>
            <AnimationContainer>
              <Lottie
                animationData={successAnimation}
                loop={false}
              />
            </AnimationContainer>
          </SuccessMessageContainer>
        ) : (
          <RegistrationForm
            setisMessageSent={setisMessageSent}
          />
        )}
      </div>
    </FormContainer>
  )
}

export default CourseRegistrationForm
