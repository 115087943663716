import styled from "styled-components"

export const FormContainer = styled.div`
  border: none;
  margin: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(153, 153, 153, 0.13);
  border-radius: 12px;
  padding: 0;
  border-radius: 25px;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
  @media (max-width: 480px) {
    padding: 15px;
  }
`

export const FormTitle = styled.h2`
  margin-bottom: 12px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #060d18;
  @media (max-width: 767px) {
    margin-bottom: 22px;
    padding: 0 6px;
  }
`
export const FormDescription = styled.p`
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  max-width: 400px;
  color: #555555;
  margin-bottom: 30px;
`
export const FormField = styled.div`
  margin-bottom: 15px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  label {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #777777;
    display: flex;
    width: 100%;
    margin-bottom: 5px;
  }
  input[type="text"] {
    height: 50px;
    border: 2px solid rgba(0, 53, 145, 0.3);
    border-radius: 4px;
    max-width: 100%;
    width: 100%;
    padding: 0 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  input[type="date"] {
    height: 50px;
    border: 2px solid rgba(0, 53, 145, 0.3);
    border-radius: 4px;
    max-width: 100%;
    width: 100%;
    padding: 0 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  input[type="email"] {
    height: 50px;
    border: 2px solid rgba(0, 53, 145, 0.3);
    border-radius: 4px;
    max-width: 100%;
    width: 100%;
    padding: 0 12px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  input[type="checkbox"] {
    height: 16.5px;
    width: 16.5px;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  textarea {
    height: 96px;
    border: 2px solid rgba(0, 53, 145, 0.3);
    border-radius: 4px;
    // max-width: 390px;
    width: 100%;
    padding-left: 15px;
    padding-top: 15px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  input[type="submit"] {
    width: 100%;
    height: 61px;
    background: #003591;
    border-radius: 100px;
    color: #fff;
    border: none;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    margin-top: 25px;
    &:hover {
      background: #fbda05;
      color: #003591;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const ContactContentWrapper = styled.div`
  margin-top: 73px;
  display: flex;
  justify-content: center;
  margin-bottom: 138px;
  @media (max-width: 991px) {
    margin-bottom: 90px;
  }
  @media (max-width: 767px) {
    margin-top: 40px;
    flex-direction: column;
    margin-bottom: 65px;
  }
`
export const AddressInfoContainer = styled.div`
  margin-right: 85px;
  @media (max-width: 991px) {
    margin-right: 0;
    width: 45%;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    width: 100%;
  }
`

export const AddressInfoCover = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgb(153 153 153 / 13%);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 33px;
`
export const ContactIconCover = styled.div`
  background: rgba(0, 53, 145, 0.14);
  border-radius: 6px;
  width: 51px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`
export const InfoContent = styled.div`
  h3 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #555555;
    margin-bottom: 0;
  }

  h4 {
    max-width: 165px;
    font-size: 16px;
  }
}`

export const SuccessMessageContainer = styled.div`
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(153, 153, 153, 0.13);
  border-radius: 12px;
  padding: 42px 36px;
  border-radius: 25px;
  width: 463px;
  svg {
    max-height: 656px !important;
    margin-top: -100px !important;
  }
  @media (max-width: 991px) {
    width: 65%;
    margin-left: 5%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
  @media (max-width: 480px) {
    padding: 15px;
  }
`

export const AnimationContainer = styled.div``
